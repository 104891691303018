// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiConfig } from "@src/api/apiConfig"
import { computeConfig, acquireToken } from "@src/api/utils"
import axios from "axios"

export const loggedIn = 1
export const loggedOut = 0

export const postChangeSessionGroup = createAsyncThunk('user/changeSessionGroup', async params => {
  try {
    const accounts = params.accounts
    const body = params.body
    const token = await acquireToken(params.instance, accounts)
    const setLoading = params.setLoading
    const postOperations = params.postOperations
    const response = await axios.post(`${apiConfig.userRbacChangeSessionGroup}`, body, computeConfig(token))
    setLoading(false)
    params.setErrorModalDetails({title:'Success', description: `You are now using the ${body.name} organization`, button: 'Ok', redirect: console.log()})
    params.setNotSelectedErrorModal(true)
    postOperations()
    return {
      data: response.data.data
    } 
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    setLoading(false)
    params.setErrorModalDetails({title:err.response.data.metadata.title, description: err.response.data.metadata.description, button: 'Ok', redirect: console.log()})
    params.setNotSelectedErrorModal(true)
    
    return rejectWithValue(err.response.data)
  }
})

export const getUserRbacMyGroups = createAsyncThunk('user/getUserRbacMyGroups', async params => {
  const accounts = params.accounts
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.get(`${apiConfig.userRbacMyGroups}`, computeConfig(token))
  return {
    data: response.data.data
  } 
})

export const logoutDisableSession = createAsyncThunk('user/disableSession', async params => {
  const accounts = params.accounts
  const logoutMsal = params.logoutMsal
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.get(`${apiConfig.userLogoutSession}`, computeConfig(token))
  console.log(response)
  logoutMsal()
  return {
    data: response.data.data
  } 
})

export const getUserRbacUsersByGroup = createAsyncThunk('user/getUserRbacUsersByGroup', async params => {
  const accounts = params.accounts
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.get(`${apiConfig.userRbacUsersByGroup}`, computeConfig(token))
  return {
    data: response.data.data
  } 
})

export const getUserRbacUsersByGroupDisabled = createAsyncThunk('user/getUserRbacUsersByGroupDisabled', async params => {
  const accounts = params.accounts
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.get(`${apiConfig.userRbacUsersByGroupDisabled}`, computeConfig(token))
  return {
    data: response.data.data
  } 
})

export const putUserStatus = createAsyncThunk('PUTuser/putUserStatus', async params => {
  console.log(params)
  const accounts = params.accounts
  const body = params.body
  const setLoading = params.setLoading
  const updateUser = params.updateUser
  try {
    const token = await acquireToken(params.instance, accounts)
    const response = await axios.put(`${apiConfig.disableUserRbacUsers}`, body, computeConfig(token))
    setLoading(false)
    updateUser()
    params.setErrorModalDetails({title:'Success', description: `The user ${body.email} was deleted from your group`, button: 'Ok', redirect: console.log()})
    params.setNotSelectedErrorModal(true)
    
    return {
      data: response.data.data
    } 
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    setLoading(false)
    params.setErrorModalDetails({title:err.response.data.metadata.title, description: err.response.data.metadata.description, button: 'Ok', redirect: console.log()})
    params.setNotSelectedErrorModal(true)
    
    return rejectWithValue(err.response.data)
  }
})

export const addUserByAdmin = createAsyncThunk('POSTapi/user/rbac/invite', async params => {
  console.log(params)
  const accounts = params.accounts
  const body = params.body
  const setLoading = params.setLoading
  const updateUser = params.updateUser
  try {
    const token = await acquireToken(params.instance, accounts)
    const response = await axios.post(`${apiConfig.userInviteByAdmin}`, body, computeConfig(token))
    setLoading(false)
    updateUser()
    params.setErrorModalDetails({title:'Success', description: `The user ${body.email} was successfuly added to your group`, button: 'Ok', redirect: console.log()})
    params.setNotSelectedErrorModal(true)
    return {
      data: response.data.data
    } 
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    setLoading(false)
    params.setErrorModalDetails({title:err.response.data.metadata.title, description: err.response.data.metadata.description, button: 'Ok', redirect: console.log()})
    params.setNotSelectedErrorModal(true)

    return rejectWithValue(err.response.data)
  }
})

export const userSlice = createSlice({
  name: 'userreducer',
  initialState: {
    status: loggedOut,
    redirectStart: false,
    userRbacActiveGroup: null,
    userRbacMyGroups: null,
    userRbacUsersByGroup: null,
    userRbacUsersByGroupDisabled: null
  },
  reducers: {
    handleLogin: state => {
      state.status = loggedIn
    },
    handleLogout: state => {
      state.status = loggedOut
      state.redirectStart = false
    },
    redirectStarted: state => {
      state.redirectStart = true
    },
    resetUserRbac: state => {
      state.userRbacMyGroups = null
      state.userRbacUsersByGroup = null
      state.userRbacUsersByGroupDisabled = null
      state.userRbacActiveGroup = null
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getUserRbacMyGroups.fulfilled, (state, action) => {
      if (action.payload.data !== null) {
        state.userRbacActiveGroup = action.payload.data.activeRbacGroup
        state.userRbacMyGroups = action.payload.data.rbacgroups
      } else {
        state.userRbacActiveGroup = null
        state.userRbacMyGroups = null
      }
    })
    .addCase(getUserRbacUsersByGroup.fulfilled, (state, action) => {
      state.userRbacUsersByGroup = action.payload.data
    })
    .addCase(getUserRbacUsersByGroupDisabled.fulfilled, (state, action) => {
      state.userRbacUsersByGroupDisabled = action.payload.data
    })
  }
  }
)

export const { handleLogin, handleLogout, redirectStarted, resetUserRbac } = userSlice.actions

export default userSlice.reducer
