// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { tokenRequest } from "@src/auth/authConfig"
import { apiConfig } from "@src/api/apiConfig"
import { computeConfig, acquireToken } from "@src/api/utils"
import axios from "axios"

export const getGroupUserPage = createAsyncThunk('groupuserpage/getGroupUserPage', async params => {
  const accounts = params.accounts
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.get(`${apiConfig.groupUserPageApi}`, computeConfig(token))
  return {
    data: response.data.data
  } 
})

export const postGroupGroup = createAsyncThunk('group/postGroup', async params => {
  const accounts = params.accounts
  const body = params.body
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.post(`${apiConfig.groupObjectApi}`, body, computeConfig(token))
  return {
    data: response.data.data
  }
})

export const putGroupGroup = createAsyncThunk('group/putGroup', async params => {
  console.log(params)
  const accounts = params.accounts
  const body = params.body
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.put(`${apiConfig.groupObjectApi}`, body, computeConfig(token))
  return {
    data: response.data.data,
    requestBody: body
  }
})

export const getMatchCampaign = createAsyncThunk('group/getMatchCampaign', async params => {
  const accounts = params.accounts
  const token = await params.instance.acquireTokenSilent({...tokenRequest, account: accounts[0]})
  const groupId = params.groupId
  const response = await axios.get(`${apiConfig.groupMatchCampaignApi}?groupId=${groupId}`, computeConfig(token))
  return {
    data: response.data.data
  }
})

export const getMatchCampaignMatches = createAsyncThunk('group/getMatchCampaignMatch', async params => {
  const accounts = params.accounts
  const token = await params.instance.acquireTokenSilent({...tokenRequest, account: accounts[0]})
  const body = params.body
  const groupId = body.groupId
  const matchcampaignId = body.id
  const response = await axios.get(`${apiConfig.groupMatchCampaignMatchesApi}?groupId=${groupId}&matchcampaignId=${matchcampaignId}`, computeConfig(token))
  return {
    data: response.data.data,
    requestBody: body
  }
})

export const postMatchCampaign = createAsyncThunk('group/postMatchCampaign', async params => {
  const accounts = params.accounts
  const body = params.body
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.post(`${apiConfig.groupMatchCampaignApi}`, body, computeConfig(token))
  const groupId = body.groupId
  const responseAll = await axios.get(`${apiConfig.groupMatchCampaignApi}?groupId=${groupId}`, computeConfig(token))
  return {
    data: response.data.data,
    dataAll: responseAll.data.data
  }
})

export const putMatchCampaign = createAsyncThunk('group/putMatchCampaign', async params => {
  const accounts = params.accounts
  const body = params.body
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.put(`${apiConfig.groupMatchCampaignApi}`, body, computeConfig(token))
  const groupId = body.groupId
  const responseAll = await axios.get(`${apiConfig.groupMatchCampaignApi}?groupId=${groupId}`, computeConfig(token))
  return {
    data: response.data.data,
    dataAll: responseAll.data.data,
    requestBody: body
  }
})

export const getGroupMemberSettings = createAsyncThunk('group/getMemberSettings', async params => {
  const accounts = params.accounts
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.get(`${apiConfig.groupMemberSettingsApi}`, computeConfig(token))
  return {
    data: response.data.data
  }
})

export const postGroupMemberSettings = createAsyncThunk('group/postMemberSettings', async params => {
  const accounts = params.accounts
  const body = params.body
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.post(`${apiConfig.groupMemberSettingsApi}`, body, computeConfig(token))
  return {
    data: response.data.data
  }
})

export const putGroupMemberSettings = createAsyncThunk('group/putMemberSettings', async params => {
  const accounts = params.accounts
  const body = params.body
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.put(`${apiConfig.groupMemberSettingsApi}`, body, computeConfig(token))
  return {
    data: response.data.data,
    requestBody: body
  }
})

export const postPlanSelected = createAsyncThunk('billing/postPlanSelected', async params => {
  const accounts = params.accounts
  const body = params.body
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.post(`${apiConfig.billingPlanSelectedApi}`, body, computeConfig(token))
  return {
    data: response.data.data
  }
})

export const putMatchCampaignStatus = createAsyncThunk('matchcampaign/status', async params => {
  console.log(params)
  const accounts = params.accounts
  const body = params.body
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.put(`${apiConfig.matchcampaignStatusApi}`, body, computeConfig(token))
  return {
    data: response.data.data,
    requestBody: body
  }
})

export const putGroupActivate = createAsyncThunk('group/activate', async params => {
  console.log(params)
  const accounts = params.accounts
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.put(`${apiConfig.groupActivateApi}`, {}, computeConfig(token))
  return {
    data: response.data.data
  }
})

export const putGroupDisable = createAsyncThunk('group/disable', async params => {
  console.log(params)
  const accounts = params.accounts
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.put(`${apiConfig.groupDisableApi}`, {}, computeConfig(token))
  return {
    data: response.data.data
  }
})

export const putGroupFirstActivate = createAsyncThunk('group/firstactivate', async params => {
  console.log(params)
  const accounts = params.accounts
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.put(`${apiConfig.groupFirstActivateApi}`, {}, computeConfig(token))
  return {
    data: response.data.data
  }
})

export const getMemberGroupStats = createAsyncThunk('member/statsByGroup', async params => {
  const accounts = params.accounts
  const token = await acquireToken(params.instance, accounts)
  const groupId = params.groupId
  const response = await axios.get(`${apiConfig.groupMemberStatsApi}?id=${groupId}`, computeConfig(token))
  return {
    data: response.data.data
  } 
})

// export const deleteInvoice = createAsyncThunk('appInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
//   await axios.delete('/apps/invoice/delete', { id })
//   await dispatch(getData(getState().invoice.params))
//   return id
// })

export const statusNotRetrieved = 0
export const statusEmpty = 1
export const statusInProgress = 2
export const statusCompleted = 3

export const groupSlice = createSlice({
  name: 'groupreducer',
  initialState: {
    group: null,
    matchcampaigns: null,
    matchcampaignSelected: null,
    campaignType: null,
    memberSettings: null,
    planSelected: null,
    memberStats: null,
    matches: [],
    matchesCampaignSelected: [],
    statusGroup: statusNotRetrieved
  },
  reducers: {
    updatePlanSelected: (state, action) => {
      state.planSelected = action.payload
    },
    updateCampaignType: (state, action) => {
      state.campaignType = action.payload
    },
    resetState: (state) => {
      state.group = null
      state.matchcampaigns = null
      state.matchcampaignSelected = null
      state.campaignType = null
      state.memberSettings = null
      state.planSelected = null
      state.memberStats = null
      state.matches = []
      state.matchesCampaignSelected = []
      state.statusGroup = statusNotRetrieved
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getGroupUserPage.fulfilled, (state, action) => {
      
      if (action.payload.data !== null) {
        const groupobjects = action.payload.data
        if (groupobjects.matchcampaigns !== null) {
          state.matchcampaigns = groupobjects.matchcampaigns
          if (groupobjects.matchcampaigns.length > 0) {
            state.matchcampaignSelected = groupobjects.matchcampaigns[0]
          } else {
            state.matchcampaignSelected = null
          }
          state.matchesCampaignSelected = []
          delete groupobjects.matchcampaign
        }
        if (groupobjects.membersetting !== null) {
          state.memberSettings = groupobjects.membersetting
          delete groupobjects.membersetting
        }
        if (groupobjects.planselected !== null) {
          state.planSelected = groupobjects.planselected
          delete groupobjects.planselected
        }
        if (groupobjects.matches !== null) {
          state.matches = groupobjects.matches
          delete groupobjects.matches
        }
        state.group = groupobjects
        if (state.group === null) {
          state.statusGroup = statusEmpty
        } else if (state.group.status === 'notCompleted') {
          state.statusGroup = statusInProgress
        } else if (state.group.status === 'disabled' || state.group.status === 'active') { 
          state.statusGroup = statusCompleted
        } 
      } else {
        state.statusGroup = statusEmpty
        state.matchcampaigns = null
        state.matchcampaignSelected = null
        state.memberSettings = null
        state.planSelected = null
        state.matches = []
        state.matchesCampaignSelected = []
        state.group = null
      }
    })
    .addCase(postGroupGroup.fulfilled, (state, action) => {
      console.log(action.payload.data)
      state.group = action.payload.data
      if (state.group === null) {
        state.statusGroup = statusEmpty
      } else if (state.group.status === 'notCompleted') {
        state.statusGroup = statusInProgress
      } else if (state.group.status === 'disabled' || state.group.status === 'active') { 
        state.statusGroup = statusCompleted
      } 
    })
    .addCase(putGroupGroup.fulfilled, (state, action) => {
      console.log(action.payload.requestBody)
      state.group = action.payload.requestBody
      if (state.group === null) {
        state.statusGroup = statusEmpty
      } else if (state.group.status === 'notCompleted') {
        state.statusGroup = statusInProgress
      } else if (state.group.status === 'disabled' || state.group.status === 'active') { 
        state.statusGroup = statusCompleted
      } 
    })
    .addCase(getMatchCampaign.fulfilled, (state, action) => {
      state.matchcampaigns = action.payload.data
      if (action.payload.data > 0) {
        state.matchcampaignSelected = action.payload.data[0]
      } else {
        state.matchcampaignSelected = null
      }
      state.matchesCampaignSelected = []
    })
    .addCase(getMatchCampaignMatches.fulfilled, (state, action) => {
      state.matchesCampaignSelected = action.payload.data
      state.matchcampaignSelected = action.payload.requestBody
      console.log(action.payload.requestBody)
    })
    .addCase(postMatchCampaign.fulfilled, (state, action) => {
      state.matchcampaignSelected = action.payload.data
      state.matchesCampaignSelected = []
      state.matchcampaigns = action.payload.dataAll
    })
    .addCase(putMatchCampaign.fulfilled, (state, action) => {
      console.log(action.payload.requestBody)
      state.matchcampaignSelected = action.payload.requestBody
      state.matchesCampaignSelected = []
      state.matchcampaigns = action.payload.dataAll
    })
    .addCase(getGroupMemberSettings.fulfilled, (state, action) => {
      state.memberSettings = action.payload.data
    })
    .addCase(postGroupMemberSettings.fulfilled, (state, action) => {
      state.memberSettings = action.payload.data
    })
    .addCase(postPlanSelected.fulfilled, (state, action) => {
      state.planSelected = action.payload.data
    })
    .addCase(putGroupMemberSettings.fulfilled, (state, action) => {
      state.memberSettings = action.payload.requestBody
    })
    .addCase(putGroupActivate.fulfilled, (state) => {
      state.group.status = 'active'
    })
    .addCase(putMatchCampaignStatus.fulfilled, (state, action) => {
      state.matchcampaignSelected.status = action.payload.requestBody.status
    })
    .addCase(putGroupFirstActivate.fulfilled, (state) => {
      state.group.status = 'active'
      state.statusGroup = statusCompleted
    })
    .addCase(putGroupDisable.fulfilled, (state) => {
      state.group.status = 'disabled'
    })
    .addCase(getMemberGroupStats.fulfilled, (state, action) => {
      state.memberStats = action.payload.data
    })
    //.addCase(fetchGeneralNewsData.rejected, (state, action) => {
    //state.status = 'failed'
    //state.error = action.error.message })
  }
})

export const { updatePlanSelected, updateCampaignType, resetState } = groupSlice.actions

export default groupSlice.reducer
