// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import groupreducer from './groupReducer'
import userreducer from './userReducer'
import memberreducer from './memberReducer'

const rootReducer = {
  navbar,
  layout,
  groupreducer,
  userreducer,
  memberreducer
}

export default rootReducer
