import { tokenRequest } from "@src/auth/authConfig"

export const computeConfig = (response) => {
    
    const config = {
        headers: {
            Authorization: `Bearer ${response.accessToken}`
        }
    }
    return config
}

export const acquireToken = async (instance, accounts) => {
    try {
        const token = await instance.acquireTokenSilent({...tokenRequest, account: accounts[0]})
        return token
    } catch (err) {
        console.error(err)
        localStorage.removeItem('userData')
        instance.logoutRedirect({postLogoutRedirectUri: "/"})
    }
}