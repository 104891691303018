// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiConfig } from "@src/api/apiConfig"
import { computeConfig, acquireToken } from "@src/api/utils"
import axios from "axios"

export const membersSearchInserted = 'inserted'
export const membersSearchApproved = 'approved'
export const membersSearchDisabledByAdmin = 'disabledByAdmin'
export const membersSearchDeletedByUser = 'deletedByUser'

export const getMembersByStatus = createAsyncThunk('GETmember/members', async params => {
  const accounts = params.accounts
  const status = params.status
  const setLoading = params.setLoading
  const adjustPage = params.page - 1
  const token = await acquireToken(params.instance, accounts)
  const response = await axios.get(`${apiConfig.groupMemberMembersApi}?status=${status}&page=${adjustPage}`, computeConfig(token))
  setLoading(false)
  return {
    data: response.data.data,
    pagination: response.data.pagination
  } 
})

export const putMembersByStatus = createAsyncThunk('PUTmember/members', async (params, { getState }) => {
  const accounts = params.accounts
  const body = params.body
  const setLoading = params.setLoading
  //const status = body.status;
  //const memberId = body.memberId;
  console.log(getState())
  const state = getState()
  const members = state.memberreducer.members
  console.log(members)
  try {
    const token = await acquireToken(params.instance, accounts)
    const response = await axios.put(`${apiConfig.groupMemberMembersApi}`, body, computeConfig(token))
    setLoading(false)
    return {
      data: response.data.data,
      memberId: body.memberId,
      setErrorModalDetails: params.setErrorModalDetails,
      setNotSelectedErrorModal: params.setNotSelectedErrorModal,
      oldMembers: members
    } 
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    setLoading(false)
    if (err.response.data.metadata.code === 'Error154') {
      params.setErrorModalDetails({title:err.response.data.metadata.title, description: err.response.data.metadata.description, button: 'Update Pricing', redirect: params.routeChangeToPricing})
    } else {
      params.setErrorModalDetails({title:err.response.data.metadata.title, description: err.response.data.metadata.description, button: 'Ok', redirect: console.log()})
    }
    params.setNotSelectedErrorModal(true)

    return rejectWithValue(err.response.data)
  }
})

export const postUserAddsMember = createAsyncThunk('POSTmember/UserAddsMember', async (params, { getState }) => {
  const accounts = params.accounts
  const body = params.body //const body = {name, email, customValue}
  const setLoading = params.setLoading
  //const status = body.status;
  //const memberId = body.memberId;
  const state = getState()
  const members = state.memberreducer.members
  const currentStatus = state.memberreducer.status
  console.log(body)
  try {
    const token = await acquireToken(params.instance, accounts)
    const response = await axios.post(`${apiConfig.groupUserAddsMemberApi}`, body, computeConfig(token))
    setLoading(false)
    params.setErrorModalDetails({title:'Success', description: `The user ${body.email} was successfuly added to your group`, button: 'Ok', redirect: console.log()})
    params.setNotSelectedErrorModal(true)
    return {
      data: response.data.data,
      setErrorModalDetails: params.setErrorModalDetails,
      setNotSelectedErrorModal: params.setNotSelectedErrorModal,
      oldMembers: members,
      status: currentStatus
    } 
  } catch (err) {
    // Use `err.response.data` as `action.payload` for a `rejected` action,
    // by explicitly returning it using the `rejectWithValue()` utility
    setLoading(false)
    if (err.response.data.metadata.code === 'Error154') {
      params.setErrorModalDetails({title:err.response.data.metadata.title, description: err.response.data.metadata.description, button: 'Update Pricing', redirect: params.routeChangeToPricing})
    } else {
      params.setErrorModalDetails({title:err.response.data.metadata.title, description: err.response.data.metadata.description, button: 'Ok', redirect: console.log()})
    }
    params.setNotSelectedErrorModal(true)

    return rejectWithValue(err.response.data)
  }
})

export const memberSlice = createSlice({
  name: 'memberreducer',
  initialState: {
    members: null,
    pagination: null,
    status: membersSearchInserted,
    page: 1
  },
  reducers: {
    updateStatus: (state, action) => {
      state.status = action.payload
    },
    updatePage: (state, action) => {
      state.page = action.payload
    },
    resetMembersValues: (state) => {
      state.members = null
      state.pagination = null
      state.status = membersSearchInserted
      state.page = 1
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getMembersByStatus.fulfilled, (state, action) => {
      state.members = action.payload.data
      state.pagination = action.payload.pagination
    })
    .addCase(getMembersByStatus.rejected, (state) => {
      state.members = null
    }
    )
    .addCase(putMembersByStatus.fulfilled, (state, action) => {
      const memberIdToBeRemoved = action.payload.memberId
      const oldMembers = action.payload.oldMembers
      const newMembers = []
      if (oldMembers !== null) {
        for (let i = 0; i < oldMembers.length; i++) {
          const member = oldMembers[i]
          if (member.id !== memberIdToBeRemoved) {
            newMembers.push(member)
          }
        }
        state.members = newMembers
      }
    })
    .addCase(postUserAddsMember.fulfilled, (state, action) => {
      const newMember = action.payload.data
      const oldMembers = action.payload.oldMembers
      const status = action.payload.status
      if (status === 'approved') {
        const newMembers = []
        if (oldMembers !== undefined) {
          for (let i = 0; i < oldMembers.length; i++) {
            const member = oldMembers[i]
            newMembers.push(member)
          }
        }
        newMembers.push(newMember)
        state.members = newMembers
      }
    })
  }
})

export const { updateStatus, updatePage, resetMembersValues } = memberSlice.actions

export default memberSlice.reducer
